$toggle-size: px-to-rem(24px);
$toggle-padding: px-to-rem(5px);

.switch {
  display: flex;
  align-items: flex-start;

  > input {
    @include visually-hidden-element;

    &:focus-visible ~ label {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }

    @supports not selector(:focus-visible) {
      &:focus ~ label {
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }
    }
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: $toggle-size + $toggle-padding * 2;

    @include round-radius;
  }

  &:not(.has-two-values) {
    > label {
      flex-grow: 1;
    }

    > input:checked ~ label > .switch-control {
      background-color: var(--colour-switch-active);
    }
  }

  .switch-control {
    display: inline-flex;
    flex-shrink: 0;
    border: none;
    padding: $toggle-padding;
    width: ($toggle-size * 2) + ($toggle-padding * 3);
    background-color: var(--colour-switch-fill);
    transition: background-color 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @include round-radius;

    @include is-touch-device {
      cursor: default;
      user-select: none;
    }

    > span {
      width: $toggle-size;
      height: $toggle-size;
      background: var(--colour-switch-toggle);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
      transition: transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transform-origin: left;

      @include round-radius;
    }
  }

  > input:active ~ label > .switch-control > span,
  > label:active > .switch-control > span {
    transform: scaleX(1.125);
  }

  > input:checked ~ label > .switch-control > span {
    transform: translateX($toggle-size + $toggle-padding);
    transform-origin: right;
  }

  > input:checked:active ~ label > .switch-control > span,
  > input:checked ~ label:active > .switch-control > span {
    transform: scaleX(1.125)
    translateX($toggle-size + $toggle-padding - $toggle-size * 0.125);
  }
}

.switch.has-two-values {
  > button {
    font-size: $font-size-sm;
    background: none;
    display: inline-flex;
    border: none;
    padding-block: calc((($toggle-size + $toggle-padding * 2) - 1em) * 0.5);
    transition: color 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @include is-touch-device {
      cursor: default;
      user-select: none;
    }

    &:first-of-type {
      padding-inline-end: $space-xs;
      order: 1;
    }

    &:last-of-type {
      padding-inline-start: $space-xs;
      order: 3;
    }
  }

  > label {
    order: 2;
  }

  input ~ button {
    &:first-of-type {
      color: var(--colour-switch-value-active);
    }

    &:last-of-type {
      color: var(--colour-switch-value-inactive);
    }
  }

  input:checked ~ button {
    &:first-of-type {
      color: var(--colour-switch-value-inactive);
    }

    &:last-of-type {
      color: var(--colour-switch-value-active);
    }
  }

  &.is-colour-scheme-toggle {
    > button {
      font-size: $font-size-lg;
    }
  }
}
