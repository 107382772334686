.radio-callout {
  $radio-size: 1em;
  $radio-border-thickness: px-to-em(2px);
  $label-line-height: 1.35em;

  display: flex;
  padding: $space-md;
  font-size: $font-size-xs;
  background-color: var(--colour-bg-high-contrast);
  gap: $space-xs;
  border: 1px solid var(--colour-callout-border);
  border-radius: 5px;
  margin-bottom: $space-xs;
  flex-direction: column;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;

  span {
    line-height: $label-line-height;
  }

  &:has(input:not(:checked)) {
    span:not(:first-of-type) {
      @include muted-text;
    }
  }

  &:has(input:checked + span:first-of-type) {
    background: var(--colour-action-border);
  }

  span:not(:only-of-type):first-of-type {
    font-weight: 500;
  }

  span:first-of-type {
    font-size: $font-size-sm;

    &::before {
      content: "";
      width: $radio-size;
      height: $radio-size;
      display: inline-block;
      border-radius: $radio-size;
      box-sizing: border-box;
      border: $radio-border-thickness solid var(--colour-radio-outline);
      margin-block-end: math.div(-($label-line-height - $radio-size), 2);
      margin-inline-end: $space-xs;
    }
  }

  input {
    @include visually-hidden-element;

    &:checked + span:first-of-type {
      &::before {
        background-color: var(--colour-radio-checkmark);
        border-color: var(--colour-radio-fill);
        border-width: $radio-border-thickness * 2;
      }
    }

    &:focus-visible + span:first-of-type::before {
      outline: 4px solid var(--colour-focus-outline);
      outline-offset: 3px;
    }

    @supports not selector(:focus-visible) {
      &:focus + span:first-of-type {
        outline: 4px solid var(--colour-focus-outline);
        outline-offset: 3px;
      }
    }
  }
}
