@mixin choice-strip-end-space {
  > :last-child {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      left: 100%;
      @include horizontal-container-spacing('width');
    }
  }
}

.choice-strip {
  overflow-x: auto;
  display: flex;
  transition: scrollbar-color 0.2s ease-in-out 1s;
  max-width: 100vw;

  &:not(.has-one-visible) {
    @include choice-strip-end-space;
  }

  @include is-touch-device {
    @include hide-scrollbars;
  }

  @include subtle-scrollbars;

  &.is-gapped {
    gap: $space-xs;

    @include is-desktop {
      gap: $space-sm;
    }
  }
}

@mixin visible-items($visible-items, $item-gap: 0, $container-spacing: 0, $peek-size: 0px) {
  & {
    scroll-padding-inline-start: $container-spacing;
  }

  > * {
    $white-space: $item-gap * ($visible-items - 1);

    $peek-space-per-item: math.div($peek-size, $visible-items);
    $white-space-per-item: math.div($white-space, $visible-items);

    $base-item-width: math.div(100%, $visible-items);

    min-width: calc(#{$base-item-width} - #{$white-space-per-item} - #{$peek-space-per-item});
    max-width: calc(#{$base-item-width} - #{$white-space-per-item});
    scroll-snap-align: start;
    scroll-snap-stop: normal;

    &:not(:last-child) {
      margin-right: $item-gap;
    }
  }
}

.choice-strip.has-one-visible {
  @include horizontal-container-spacing;
  @include extra-desktop-space-horizontal;
  @include hide-scrollbars;

  @include visible-items(1, $space-sm, $mobile-container-spacing, $space-sm);

  @include is-tablet {
    // Enable smooth scroll behaviour, but not in the test suite because Cypress has a bug they
    // only fixed for smooth scrolling on the whole page and not elements with scrollable areas.
    // See https://github.com/cypress-io/cypress/issues/3200 for details.
    html:not([data-cypress]) & {
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
    }

    @include visible-items(2, $space-md, $tablet-container-spacing);
  }

  @include is-desktop {
    @include visible-items(3, $space-md, $desktop-container-spacing);
  }

  @include is-desktop-wide {
    @include visible-items(4, $space-md, $extra-desktop-container-spacing);
  }

  @include is-desktop-super-wide {
    @include visible-items(5, $space-lg, $extra-desktop-container-spacing);
  }

}

.choice-strip-controls {
  display: flex;
  align-items: center;
  gap: $space-xs;

  @include is-not-tablet {
    @include completely-hidden-element;
  }
}

.divider.has-content.choice-strip {
  transition: padding-left 0.1s ease-in;
  position: relative;

  // Smaller top spacing on mobile when choice strip immediately follows the main header
  @include is-not-tablet {
    header + & {
      padding-top: px-to-rem(4px);
    }
  }
}
