.link-selector {
  padding: px-to-rem(6px) 0;
  outline: none;
  cursor: pointer;
  background: none;

  @include animate-subtle;
  @include remove-default-tap-highlights;
  @include remove-hover-underline;

  > span {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    padding-left: px-to-rem(3px);
    padding-right: px-to-rem(3px);
    gap: $space-xs;

    @include animate-subtle;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      padding: px-to-rem(3px) 0;
    }
  }

  .icon {
    color: var(--colour-text-input-icon);
  }

  &.has-plain-icon .icon {
    color: var(--colour-text);
  }

  @include is-desktop {
    padding: 0;

    > span {
      font-size: $font-size-lg;

      > span {
        padding: px-to-rem(4px) 0;
      }

      > svg:last-of-type:not(:first-of-type) {
        margin-left: $space-xs;
      }
    }
  }

  &:focus > span,
  &:hover > span {
    background-color: var(--link-selector-hover);
    box-shadow: 0px 0px 0px 4px var(--link-selector-hover);
    border-radius: 3px;
  }

  &.will-shrink {
    max-width: 100%;
    min-width: 0px;
  }
}

.option-bar.is-tiny .link-selector {
  padding: px-to-rem(4px) 0;

  > span {
    font-size: $font-size-xs;

    & > span {
      padding: px-to-rem(6px) 0;
    }
  }
}
