$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-wide: 1600px;
$breakpoint-desktop-super-wide: 2048px;

$breakpoint-tablet-inverse: $breakpoint-tablet - 1px;
$breakpoint-desktop-inverse: $breakpoint-desktop - 1px;
$breakpoint-desktop-wide-inverse: $breakpoint-desktop-wide - 1px;
$breakpoint-desktop-super-wide-inverse: $breakpoint-desktop-super-wide - 1px;

// Breakpoints
@mixin is-tablet {
  @media (min-aspect-ratio: 3/4) and (min-width: $breakpoint-tablet) { @content; }
}

@mixin is-desktop {
  @media (min-aspect-ratio: 3/4) and (min-width: $breakpoint-desktop) { @content; }
}

@mixin is-desktop-wide {
  @media (min-aspect-ratio: 1/1) and (min-width: $breakpoint-desktop-wide) { @content; }
}

@mixin is-desktop-super-wide {
  @media (min-aspect-ratio: 1/1) and (min-width: $breakpoint-desktop-super-wide) { @content; }
}

// Inverse breakpoints
@mixin is-not-tablet {
  @media (max-aspect-ratio: 3/4), (max-width: $breakpoint-tablet-inverse) { @content; }
}

@mixin is-not-desktop {
  @media (max-aspect-ratio: 3/4), (max-width: $breakpoint-desktop-inverse) { @content; }
}

@mixin is-not-desktop-wide {
  @media (max-aspect-ratio: 1/1), (max-width: $breakpoint-desktop-wide-inverse) { @content; }
}

@mixin is-not-desktop-super-wide {
  @media (max-aspect-ratio: 1/1), (max-width: $breakpoint-desktop-super-wide-inverse) { @content; }
}

// Device features
@mixin has-mouse-pointer {
  @media (hover: hover) { @content; }
}

@mixin is-touch-device {
  @media (hover: none) { @content; }
}

@mixin font-size($px-value) {
  font-size: px-to-rem($px-value);
}

// Visual elevation levels
@mixin elevation-low-subtle {
  box-shadow: 0px 4px 4px var(--colour-elevation-low-subtle), 0px -2px 26px var(--colour-elevation-low-subtle);
}

@mixin elevation-low {
  box-shadow: 0px 4px 4px var(--colour-elevation-low), 0px -2px 26px var(--colour-elevation-low);
}

@mixin elevation-middle {
  box-shadow: 0px 4px 4px ;
  box-shadow: 0px 0px 10px 0px var(--colour-elevation-middle-1), 0px 4px 6px 0px var(--colour-elevation-middle-2);
}

@mixin elevation-high {
  box-shadow: 0px 4px 28px var(--colour-elevation-high);
}

@mixin elevation-high-subtle {
  box-shadow: 0px 4px 28px var(--colour-elevation-high-subtle);
}

// Visual helpers
@mixin animate-subtle($properties: all) {
  transition-property: #{$properties};
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}

@mixin divider-line {
  border-bottom: 1px solid var(--colour-divider);
}

// Hides an element from people with eyes, but still allow screen readers to read it out
@mixin visually-hidden-element {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Completely hides the element from people with eyes and screen readers
@mixin completely-hidden-element {
  display: none !important;
}

// Hides an element when it contains no content
@mixin hidden-when-empty {
  &:where(:empty) {
    @include completely-hidden-element;
  }
}

// Allows hiding elements in a way where transitions are supported
@mixin hidden-element-can-animate {
  opacity: 0;
  visibility: hidden;
}

@mixin hide-scrollbars {
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// Nicer scrollbar style for desktop
@mixin subtle-scrollbars {
  @include has-mouse-pointer {
    -ms-overflow-style: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &:hover {
      scrollbar-color: hsla(0, 0%, 40%, 0.5) transparent;
      transition-delay: 0s;

      &::-webkit-scrollbar-thumb {
        background-color: hsla(0, 0%, 40%, 0.5);
      }
    }
  }
}

// Aspect ratio based on element width
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    display: block;
    padding-top: math.div($height, $width) * 100%;
  }
}

// Visual helpers
@mixin remove-hover-underline {
  &:hover {
    text-decoration: none;
  }
}

@mixin remove-default-tap-highlights {
  @include is-touch-device {
    cursor: default;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
  }
}

@mixin round-radius {
  border-radius: 9999px;
}

//Offsets
@mixin floating-back-button-offset {
  margin-top: -$floating-back-button-offset;
}

// Gradients & fills
@mixin background-gradient-primary {
  background: var(--gradient-primary);
}
